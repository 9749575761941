import { Component } from '@angular/core';
import moment from "moment"
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { Const } from '@const/Const';
import {FormControl, Validators} from '@angular/forms';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ZipcodeSelectorValue } from '@app/admin/components/common/zipcode-selector';
import { DeliveryInfo, VehicleType } from '@wearewarp/types/data-model';
import { DedicatedLaneHelper } from '../../dedicated-lane.helper';
import { ShipmentEntryCreateMode } from '@app/enum';
import { ShipmentEntryContainer } from '@app/admin/shipment-entry/container';
import { DialogService } from '@dialogs/dialog.service';
import { DedicatedLaneData } from '../../dedicated-lane.interface';

@Component({
  selector: 'dedicated-lanes-manager-order',
  templateUrl: './index.html',
  styleUrls: [
    '../../../detail.scss',
    "../../../../../styles/row-col.scss",
    "../../../../../styles/form-v2.scss",
    './index.scss'
  ]
})
export class DedicatedLanesOrderManager extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: {label: 'Customer', required: true, readOnly: true},
    shipmentType: {label: 'Shipment Type', required: true, readOnly: true},
    vehicleType: {label: 'Equipment', required: false, readOnly: true},
    fromZipcode: {label: 'From zip code', required: true, readOnly: true},
    toZipcode: {label: 'To zip code', required: true, readOnly: true},
    rate: {label: 'Rate', type: 'number', required: true, readOnly: true, placeHolder: `$0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value)},
    weeklyOrdersConvert: {label: 'Weekly Orders', required: true, readOnly: true},
    expiredDate: {label: 'Expiration (MM/DD/YYYY)', required: false, readOnly: true},
  }

  listClients = [];
  public listShipmentTypes = Const.ShipmentTypesArray;
  public vehicleType: VehicleType;
  weeklyOrdersConvert = '';
  public limit: number = Const.PAGINATION_LIMIT;
  public pageIndex: number = 1;
  public totalCount: number;
  isOrderLoading = false;
  public orders = [];
  selectedDate = moment().format("MM/DD/YYYY");
  needCreatedOrderCount: number = 0;
  listSubClients = [];

  private zipcodeSelectorOrigin: ZipcodeSelectorValue;
  private zipcodeSelectorDestination: ZipcodeSelectorValue;

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  protected getApiUrl(): string {
    return Const.APIV2('dedicated_lane');
  }

  getApiListDataForFilter(key: string) {
    switch (key) {
      case 'clientId': return Const.APIURI_CLIENTS_FOR_FILTER;
    }
  }

  ngOnInit(): void {
    this.getOrderList();
  }
  ngOnChanges(): void {
    console.log(this.model);
  }

  onChangePage(value: number) {
    this.pageIndex = value;
    this.getOrderList();
  }
 

  getOrderList() {
    this.isOrderLoading = true;
    const skip = this.pageIndex ? (this.pageIndex - 1) * this.limit : 0;
    const filter = {
      date: this.selectedDate,
      // clientIds: [
      //   this.model?.clientId
      // ],
      // pickupZipcode: this.zipcodeSelectorOrigin.zipcode,
      // dropoffZipcode: this.zipcodeSelectorDestination.zipcode,
      // pickupFromDate: ,
      // dropoffZipcode: ,
    };
    const url = `${Const.APIV2(Const.APIURI_ORDERS)}/orders-for-dedicated-lane/${this.id}?filter=${encodeURIComponent(JSON.stringify(filter))}&skip=${skip}&limit=${this.limit}`;
    this.api.GET(url).subscribe(
      res => {
        this.isOrderLoading = false;
        this.orders = (res.data?.list_data || []);
        this.processOrderData(this.orders);
        this.totalCount = res.data?.total;
      },
      error => {
        this.showErr(error);
        this.isOrderLoading = false;
      }
    )
  }

  public getDeliveryInfoDate(deliveryInfo: DeliveryInfo, format: string = Const.FORMAT_GUI_DATETIME) {
    let str = super.getDeliveryInfoTime(deliveryInfo, { format: 'MMM D, YYYY \n h:mm a', appointmentFlag: '\n(Appointment Scheduled)' });
    return str || 'Requires appointment';
  }

  getRefNum(item, type) {
    if (type == Const.TaskType.PICKUP) {
      let stopInfo;
      if (item.deliveryInfos) {
        stopInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      } else {
        stopInfo = item.pickInfo;
      }
      let refNums = [];
      if (stopInfo && stopInfo.refNums) refNums = stopInfo.refNums;
      refNums = refNums.filter(it => it !== '');
      return refNums
    } else {
      let stopInfo;
      if (item.deliveryInfos) {
        stopInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      } else {
        stopInfo = item.dropInfo;
      }
      let refNums = [];
      if (stopInfo && stopInfo.refNums) refNums = stopInfo.refNums
      refNums = refNums.filter(it => it !== '');
      return refNums
    }
  }


  processOrderData(orders) {
    for (let item of orders) {
      let getShipmentData = (record) => {
        let pickInfo = record?.deliveryInfos?.filter(it => it.type == Const.TaskType.PICKUP)[0];
        let dropInfo = record?.deliveryInfos?.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        let { status, trackingCode, type, legs, subs, code } = record;
        let iUnrecognizedAddr = (!pickInfo?.addr?.metadata?.timeZoneStandard || !dropInfo?.addr?.metadata?.timeZoneStandard)
        return {
          status, trackingCode, type, legs, subs, code,
          addrPickFull: this.getAddressText(pickInfo?.addr),
          addrDropFull: this.getAddressText(dropInfo?.addr),
          timePick: this.getDeliveryInfoDate(pickInfo),
          timeDrop: this.getDeliveryInfoDate(dropInfo),
          locationNamePick: pickInfo?.locationName || '',
          locationNameDrop: dropInfo?.locationName || '',
          clientSuccessRep: this.getFullName(record.clientSuccessRep),
          clientSalesRep: this.getFullName(record.clientSalesRep),
          dispatcher: this.getFullName(record.dispatcher),
          carrierSaleRep: this.getFullName(record.carrierSaleRep),
          refNumsPickup: this.getRefNum(record, Const.TaskType.PICKUP),
          refNumsDropoff: this.getRefNum(record, Const.TaskType.DROPOFF),
          shipmentType: this.getShipmentTypeName(record.shipmentType),
          tempRange: record.tempRange || "",
          jobIds: record.jobIds || [],
          pod: record.pod || {},
          iUnrecognizedAddr
        }
      }
      let { shipmentIds = [], metadata } = item;
      if (shipmentIds.length) {
        let shipment = this.getShipment(metadata.shipments);
        const displayInfo = getShipmentData(shipment);
        if (displayInfo.iUnrecognizedAddr) {
          item.warningMessage = 'Unrecognized address'
        }
        item.displayInfo = displayInfo;
      }
    }
  }

  getShipment(shipments) {
    for (const shipment of shipments) {
      let pickInfo = shipment?.deliveryInfos?.find(it => it.type == Const.TaskType.PICKUP);
      let dropInfo = shipment?.deliveryInfos?.find(it => it.type == Const.TaskType.DROPOFF);
      const pickZipcode = pickInfo?.addr?.zipcode;
      const dropZipcode = dropInfo?.addr?.zipcode;
      if (pickZipcode == this.zipcodeSelectorOrigin.zipcode && dropZipcode == this.zipcodeSelectorDestination.zipcode) {
        return shipment;
      }
    }
    return null;
  }

  get shouldDisableVehicleType() {
    if (!this.isCreateNew) return true;
    return this.getItemValue('shipmentType') !== Const.ShipmentTypes.fullTruckLoad;
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'rate':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'shipmentType':
        this.onChangeShipmentType(event)
        break;
      case 'rate':
        InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
        break;
      case 'vehicleType':
        this.setItemValue('vehicleType', event);
        break;
      case 'fromZipcode':
        this.zipcodeSelectorOrigin = event;
        this.setItemValue('fromZipcode', event);
        break;
      case 'toZipcode':
        this.zipcodeSelectorDestination = event;
        this.setItemValue('toZipcode', event);
        break;
      default:
        super.onInputChanged(event, key);
        break;
    }
  }

  onChangeWeeklyOrderEnabled(key, day, value) {
    if (value) {
      this.formInput.get(key)?.get(day)?.get('quantity')?.setValue(1);
    } else {
      this.formInput.get(key)?.get(day)?.get('quantity')?.setValue(null);
    }
  }

  isDayEnable(key, day) {
    const value = this.formInput.get(key)?.get(day)?.get('enabled')?.value;
    return value
  }

  onChangeShipmentType(value) {
    if (value === Const.ShipmentTypes.fullTruckLoad) {
      this.formGroupDeclaration.vehicleType.required = true;
      this.updateRequireField("vehicleType", true);
    } else {
      this.formGroupDeclaration.vehicleType.required = false;
      this.updateRequireField("vehicleType", false);
    }
    this.vehicleType = null;
    this.setItemValue("vehicleType", null);
  }

  public updateRequireField(path, value){
    let fc = <FormControl>this.formInput.get(path);
    if (fc) {
      if (!value) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  getValueInitZipcode(key) {
    const value = this.getItemValue(key);
    if (Utils.isObjectNotEmpty(value) && value.zipcode) {
      return value.zipcode;
    }
    return value
  }

  protected onGetDetailSuccess(data) {
    data.fromZipcode = data.origin?.zipcode;
    data.toZipcode = data.destination?.zipcode;
    this.zipcodeSelectorOrigin = data.origin;
    this.zipcodeSelectorDestination = data.destination;
    this.vehicleType = data.vehicleType;
    data.weeklyOrdersConvert = DedicatedLaneHelper.getWeeklyOrders(data.weeklyOrders);
    this.needCreatedOrderCount = DedicatedLaneHelper.getQuantityByDate(this.selectedDate, data.weeklyOrders)
    if (data.subClient?.id) {
      this.listSubClients = [data.subClient]
    }
    return data;
  }

  public onBtnDel() {
    this.confirmDeletion({
      message: `Delete Lane <b>${this.model?.code ?? ''}</b>?`,
      fnOk: () => {
        this.deleteLane();
      }
    });
  }

  deleteLane() {
    let url = `${this.getApiUrl()}/${this.id}`;
    this.api.DELETE(this.api.buildUrl(url)).subscribe(
      resp => {
        this.showSuccess(`Lane ${this.model?.code ?? ''} has been deleted successfully.`);
        this.router.navigate([this.routeAdminDedicatedLanes], { replaceUrl: true });
      }, err => {
        this.showErr(err);
      }
    );
  }

  createOrderManual() {
    this.openDialogShipmentEntry({
      type: ShipmentEntryCreateMode.single,
      dedicatedLane: {
        id: this.model?.id,
        origin: this.model?.origin,
        destination: this.model?.destination,
        rate: this.model?.rate,
        clientId: this.model?.clientId,
        shipmentType: this.model?.shipmentType,
        vehicleType: this.vehicleType,
        pickupDate: this.selectedDate, 
      },
      modelDraft: {
        shipmentEntryMode: ShipmentEntryCreateMode.single,
        basicInfo: {
          vehicleType: this.vehicleType,
          clientId: this.model?.subClientId ? this.model?.subClientId : this.model?.clientId,
          parentClientId: this.model?.subClientId ? this.model?.clientId : null,
          shipmentType: this.model?.shipmentType,
        },
      },
    });
  }
  openDialogShipmentEntry(ops: { type?: ShipmentEntryCreateMode, modelDraft?: any, model?: any, dedicatedLane: DedicatedLaneData}) {
    DialogService.openDialog(ShipmentEntryContainer, {
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: 'modal-fullscreen modal-no-padding',
      nzComponentParams: {
        createMode: ops.type,
        modelDraft: ops.modelDraft,
        model: ops.model,
        dedicatedLane: ops?.dedicatedLane,
        onComplete: (data) => {
          this.getOrderList();
        }
      },
      
    });
  }

  onCalendarValueChange(value: Date): void {
    this.selectedDate = moment(value).format("MM/DD/YYYY");
    this.getOrderList();
    if (this.model?.weeklyOrders) {
      this.needCreatedOrderCount = DedicatedLaneHelper.getQuantityByDate(this.selectedDate, this.model.weeklyOrders)
      if (this.model?.expiredDate) {
        const checkDateExpried = this.compareDatesExpried(this.model?.expiredDate, this.selectedDate)
        if (checkDateExpried) {
          this.needCreatedOrderCount = 0;
        }
      }
    }
  }

  onCalendarPanelChange(change: { date: Date; mode: string }): void {
    console.log(`Current value: ${change.date}`);
    console.log(`Current mode: ${change.mode}`);
  }

  compareDatesExpried(date1, date2) {
    // Chuyển đổi chuỗi "MM/DD/YYYY" thành đối tượng Date
    const [month1, day1, year1] = date1.split('/');
    const [month2, day2, year2] = date2.split('/');
  
    const formattedDate1 = new Date(year1, month1 - 1, day1);  // month1 - 1 vì tháng trong JavaScript bắt đầu từ 0
    const formattedDate2 = new Date(year2, month2 - 1, day2);  // month2 - 1 vì tháng trong JavaScript bắt đầu từ 0
  
    // So sánh hai đối tượng Date
    if (formattedDate1 >= formattedDate2) {
      return false;
    }
    return true;
  }

  public isHighlight(date: Date) {
    const dayName = DedicatedLaneHelper.getDayName(date);

    const schedule = this.model?.weeklyOrders;
    if (schedule) {
      if (schedule[dayName]?.enabled) {
        if (this.model?.expiredDate) {
          const dateConver = moment(date).format("MM/DD/YYYY");
          const checkDateExpried = this.compareDatesExpried(this.model?.expiredDate, dateConver)
          if (checkDateExpried) return false;
        }
        return true
      }
      return false;

    }
  }

  get checkDateExpired(): boolean {
    return this.model?.expiredDate &&
      this.compareDatesExpried(this.model.expiredDate, this.selectedDate);
  }
}
