import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { CloneShipment } from "@app/admin/shipments/clone-shipment";
import { DialogService } from "@dialogs/dialog.service";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
import { Order } from "@wearewarp/types/data-model";
import { ResponseWhenBy } from "@wearewarp/types/rest-api/common";
import { getLinkDashboard } from "@services/routerlink";

@Component({
  selector: '[dedicated-lane-list-order]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class DedicatedLaneListOrder extends BaseComponent {
  constructor() {
    super();
  }
  @Input() totalCount: number = 0;
  @Input() isLoading: boolean = false;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  public limit: number = Const.PAGINATION_LIMIT;
  public pageIndex: number = 1;
  @Input() orders: any;
  
  ngOnInit() {
  }

  ngOnChanges() {
  }

  
  onChangePage(value: number) {
    this.pageChange.emit(value);
  }

  getRouterLink(item) {
    let id = item.id;
    if (!this.isOrder(item)) {
      id = item?.parent?.id;
    }
    if(!id) return;
    return [this.routeAdminOrderList, id];
  }

  isOrder(item) {
    if (item.shipmentIds) return true;
    return false;
  }

  getCreatedDate(order: Order) {
    const shipment = order.metadata?.shipments?.[0];
    const pickInfo = shipment?.deliveryInfos?.find(it => it.type == Const.TaskType.PICKUP);
    const timezone = pickInfo?.addr?.metadata?.timeZoneStandard ?? 'America/Los_Angeles';
    const createdAt = order.insert?.when;
    return `${DateUtil.displayTimeWindow({
      from: createdAt,
      to: createdAt
    }, {
      timezone: timezone,
      formatDateOnly: 'MMM D, YYYY',
      format: "MMM D, YYYY h:mm A",
    })} ${this.getDisplayTimezone(timezone)}`;
  }

  private getDisplayTimezone(timezone){
    return DateUtil.timezoneStandardToUsShort(timezone)
  }

  onBtnCloneShipment(order) {
    if (this.isAdminReadOnlyRole) return;
    DialogService.openFormDialog1(CloneShipment, {
      nzComponentParams: {
        orderId: order.id,
        closeOnSuccess: true,
        createSuccess: resp => { 
          let msg = 'Clone shipment successfully.';
          if (Utils.isStringNotEmpty(resp?.message)) {
            msg = resp.message;
          }
        },
        onSubmitSuccess: (value) => this.onChangePage(1),
      },
      nzClassName: 'modal-no-padding modal-fullscreen',
    });
  }

  getCreatedUserName(item: {insert: ResponseWhenBy}): string {
    let name = item?.insert?.by?.name ?? '';
    if (!name && item?.insert?.selfRegistered) return '[Self Registered]';
    if (item?.insert?.by?.entity && item.insert.by.entity != 'users') {
      const map = {
        clients: 'Customer',
        carriers: 'Carrier',
        drivers: 'Driver'
      };
      const entiryName = map[item.insert.by.entity] ?? item.insert.by.entity;
      if (entiryName) {
        if (!name) {
          name = 'NO NAME';
        }
        name += ` (${entiryName})`;
      }
    }
    return name;
  }
  getCreatedUserLink(item: {insert: ResponseWhenBy}): string|undefined {
    if (!item?.insert?.by?.id) return undefined;
    return getLinkDashboard(item.insert.by);
  }

  showOrderSource(source) {
    switch (source) {
      case Const.thirdPartyOrderSource.orderful:
        return 'EDI';
      case Const.thirdPartyOrderSource.walmart:
        return 'API';
      case Const.thirdPartyOrderSource.WarpAPIFreightQuote:
        return 'API';
      case Const.thirdPartyOrderSource.WarpFreightQuote:
        return 'Freight quote';
      }
    return source;
  }

  public static readonly thirdPartyOrderSource = {
    orderful: "orderful",
    walmart: 'walmart',
    DAT: 'DAT',
    Exfreight: 'Exfreight',
    WarpFreightQuote: 'WarpFreightQuote',
    WarpAPIFreightQuote: 'WarpAPIFreightQuote'
  }

  onBtnCopyPickRef(item) {
    const text = item.displayInfo.refNumsPickup.join(', ');
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Copy successful.');
      }
    });
  }
  onBtnCopyDropRef(item) {
    const text = item.displayInfo.refNumsDropoff.join(', ');
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Copy successful.');
      }
    });
  }
}