import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { Const } from '@const/Const';
import {FormControl, Validators} from '@angular/forms';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ZipcodeSelectorValue } from '@app/admin/components/common/zipcode-selector';
import { VehicleType } from '@wearewarp/types/data-model';
import { ResponseCustomerLaneDetailUI } from './interface';
import dayjs from "dayjs";
import { ApiMethod } from '@app/enum';
import { Log } from '@services/log';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dedicated-lanes-detail',
  templateUrl: './view.html',
  styleUrls: [
    '../../detail.scss',
    "../../../../styles/row-col.scss",
    "../../../../styles/form-v2.scss",
    './style.scss'
  ]
})
export class DedicatedLanesDetail extends BaseDetail<ResponseCustomerLaneDetailUI> {
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: {label: 'Customer', required: true},
    shipmentType: {label: 'Shipment Type', required: true},
    vehicleType: {label: 'Equipment', required: false},
    fromZipcode: {label: 'From zip code', required: true},
    toZipcode: {label: 'To zip code', required: true},
    rate: {label: 'Rate', type: 'number', required: true, placeHolder: `$0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value)},
    weeklyOrders: {
      label: 'Weekly Orders', type: 'formGroup', required: true, childItem: {
        monday: {label: 'Monday', type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
        tuesday: { label: "Tuesday", type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
        wednesday: { label: "Wednesday", type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
        thursday: { label: "Thursday", type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
        friday: { label: "Friday", type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
        saturday: { label: "Saturday", type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
        sunday: { label: "sunday", type: 'formGroup', required: true, childItem: {
          enabled: {label: '', type: 'boolean', initialValue: false},
          quantity: {label: 'Order Quantity', type: 'number'},
        }},
      },
    },
    expiredDate: {label: 'Expiration (MM/DD/YYYY)', required: false},
  }

  listClients = [];
  public listShipmentTypes = Const.ShipmentTypesArray;
  public vehicleType: VehicleType;


  listSubClients = [];
  selectedSubClient = null;
  selectedClient = null;
  subClientSubscription: Subscription;

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  protected getApiUrl(): string {
    return Const.APIV2('dedicated_lane');
  }

  getApiListDataForFilter(key: string) {
    switch (key) {
      case 'clientId': return Const.APIURI_CLIENTS_FOR_FILTER;
    }
  }
  get needUpdate(): boolean {
    if (this.isCreateNew) {
      const weeklyOrders = this.formInput.get('weeklyOrders').value;
      const allDisabled = Object.values(weeklyOrders).every((day: any) => !day.enabled);
      if (allDisabled) {
        return false;
      }
    }
    return super.needUpdate;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onBtnEdit();
    if (!this.isCreateNew) {
      this.formGroupDeclaration.rate.readOnly = true;
    }
  }
  
  protected getFormData_JSON(isCreateNew: boolean) {
    const json = super.getFormData_JSON(isCreateNew);
    const vehicleType: VehicleType | undefined = json.shipmentType === Const.ShipmentTypes.fullTruckLoad && json.vehicleType ? json.vehicleType : undefined;
    const data: any = {
      clientId: json.clientId,
      shipmentType: json.shipmentType,
      origin: this.zipcodeSelectorOrigin,
      destination: this.zipcodeSelectorDestination,
      rate: json.rate,
      expiredDate: json.expiredDate,
      weeklyOrders: json.weeklyOrders,
      subClientId: json.subClientId,
    }
    if (vehicleType) {
      data.vehicleType = vehicleType;
    }
    if (json.expiredDate) {
      data.expiredDate = dayjs(json.expiredDate).format('MM/DD/YYYY');
    }
    return data
  }

  get shouldDisableVehicleType() {
    if (!this.isCreateNew) return true;
    return this.getItemValue('shipmentType') !== Const.ShipmentTypes.fullTruckLoad;
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'rate':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  private zipcodeSelectorOrigin: ZipcodeSelectorValue;
  private zipcodeSelectorDestination: ZipcodeSelectorValue;

  onInputChanged(event, key) {
    switch (key) {
      case 'shipmentType':
        this.onChangeShipmentType(event)
        break;
      case 'rate':
        InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
        break;
      case 'vehicleType':
        this.setItemValue('vehicleType', event);
        break;
      case 'fromZipcode':
        this.zipcodeSelectorOrigin = event;
        this.setItemValue('fromZipcode', event);
        break;
      case 'toZipcode':
        this.zipcodeSelectorDestination = event;
        this.setItemValue('toZipcode', event);
        break;
      default:
        super.onInputChanged(event, key);
        break;
    }
  }

  onChangeWeeklyOrderEnabled(key, day, value) {
    if (value) {
      this.formInput.get(key)?.get(day)?.get('quantity')?.setValue(1);
    } else {
      this.formInput.get(key)?.get(day)?.get('quantity')?.setValue(null);
    }
  }

  isDayEnable(key, day) {
    const value = this.formInput.get(key)?.get(day)?.get('enabled')?.value;
    return value
  }

  onChangeShipmentType(value) {
    if (value === Const.ShipmentTypes.fullTruckLoad) {
      this.formGroupDeclaration.vehicleType.required = true;
      this.updateRequireField("vehicleType", true);
    } else {
      this.formGroupDeclaration.vehicleType.required = false;
      this.updateRequireField("vehicleType", false);
    }
    this.vehicleType = null;
    this.setItemValue("vehicleType", null);
  }

  public updateRequireField(path, value){
    let fc = <FormControl>this.formInput.get(path);
    if (fc) {
      if (!value) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  getValueInitZipcode(key) {
    const value = this.getItemValue(key);
    if (Utils.isObjectNotEmpty(value) && value.zipcode) {
      return value.zipcode;
    }
    return value
  }

  protected onGetDetailSuccess(data) {
    data.fromZipcode = data.origin?.zipcode;
    data.toZipcode = data.destination?.zipcode;
    this.zipcodeSelectorOrigin = data.origin;
    this.zipcodeSelectorDestination = data.destination;
    this.vehicleType = data.vehicleType;
    if (data.subClient?.id) {
      this.listSubClients = [data.subClient]
    }
    return data;
  }

  protected onUpdateSuccess(data) {
    super.onUpdateSuccess(data);
    this.router.navigate([this.routeAdminDedicatedLanes, this.id, 'order-manager'], { replaceUrl: true });
  }


  protected getData() {
    let url = this.buildUrl(ApiMethod.get);
    if (!url) {
      return Log.e(`[${this.TAG}] getApiUrl is empty`);
    }
    this.startProgress();
    let routeParams = this.routeParam();
    if (Utils.isObjectNotEmpty(routeParams)) {
      let qs = new URLSearchParams(routeParams).toString();
      url += '?' + qs;
    }
    this.api.GET(url).subscribe(
      resp => {
        this.model = this.onGetDetailSuccess(resp.data);
        Log.d('getData model ', this.model);
        // this.bindDataModel(this.model);
        this.createFormInput(this.model);
        this.setEnableFormGroup(true);
        this.stopProgress();
        this.handleHashAfterGotData();
        this.handleUpdateMissingModelData();
      }, err => {
        this.showErr(err);
        this.stopProgress()
      }
    );
  }
  
  protected doCancel() {
    if (this.isCreateNew) {
      this.reset();
      this.createFormInput();
      this.location.back();
    } else {
      this.router.navigate([this.routeAdminDedicatedLanes, this.id, 'order-manager'], { replaceUrl: true });
    } 
  }

  private onSubClientChange(clientId) {
    // get selected sub-client data
    this.selectedSubClient = this.listSubClients.find(item => item.id === clientId);
  }

  private fetchSubClient(parentId: string,subClientId:string = null) {
    this.subClientSubscription?.unsubscribe();
    let url = Const.APIV2(`${Const.APIURI_CLIENTS}/${parentId}/sub-client`);
    this.subClientSubscription = this.api.GET(url).subscribe(
      resp => {
        this.listSubClients = resp.data.list_data;
        if (subClientId) {
          this.selectedSubClient = this.listSubClients.find(item => item.id === subClientId);
        }
      }
    );
  }

  private onClientChange(clientId) {
    this.selectedClient = this.listClients.find(item => item.id === clientId);
    //clear old and get new sub-client data
    this.selectedSubClient = null;
    this.setItemValue('subClientId', null);
    this.listSubClients = null;
    if (this.selectedClient) {
      this.fetchSubClient(this.selectedClient.id);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subClientSubscription?.unsubscribe();
  }

  onDropdownSelectChange(key: string, event) {
    switch(key) {
      case 'clientId':
        this.onClientChange(event);
        break;
      case 'subClientId':
        this.onSubClientChange(event);
        break;
    }
    
    // this.client = this.listClients.filter(it => (it._id == itemId || it.id == itemId))[0] || {};
  }
}
