
<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap;">
    <ng-container *ngFor="let key of formInputKeys">
      <div class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container [ngSwitch]="key">

          <select-by-searching *ngSwitchCase="'clientIds'"
            [version]="2"
            type="client"
            [formControlName]="key"
            [nzMode]="'multiple'"
            [showSubClient]="true"
            dropdownClassName="customer-lane-filter-client"
            [placeholder]="getPlaceHolder(key)"
            (modelChange)="onChange(key, $event)">
          </select-by-searching>

          <div *ngSwitchCase="'vehicleType'">
            <div vehicle-selector 
              style="width: 200px;"
              [value]="getVehicleType()" 
              (valueChange)="onChange(key, $event)" 
              [quoting]="false" 
              [withOptions]="false">
            </div>
          </div>

          <input *ngSwitchDefault nz-input 
            [formControlName]="key" 
            [placeholder]="getPlaceHolder(key)" 
            (keyup)="onInputKeyUp(key, $event)"/>
        </ng-container>
      </div>
    </ng-container>

  </div>
</form>