import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTableModule } from "ng-zorro-antd/table";
import { DedicatedLanesList } from "./list";
import { ModuleWPaginator } from "@app/admin/components/paginator/module";
import { DedicatedLanesListFilterComponent } from "./components/list-filter";
import { DedicatedLanesRoutingModule } from "./dedicated-lanes.routing";
import { SharedModule } from "../shared/shared.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { UiCommonModule } from "../components/common/module";
import { DedicatedLanesDetail } from "./detail";
import { DetailModule } from "../base/detail.module";
import { DedicatedLanesOrderManager } from "./components/manager-order";
import { DedicatedLaneListOrder } from "./components/manager-order/order-list";

@NgModule({
  imports: [
    DedicatedLanesRoutingModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzSelectModule,
    NzTableModule,
    ModuleWPaginator,
    SharedModule,
    SelectBySearchingModule,
    UiCommonModule,
    DetailModule
  ],
  declarations: [
    DedicatedLanesList,
    DedicatedLanesDetail,
    DedicatedLanesListFilterComponent,
    DedicatedLanesOrderManager,
    DedicatedLaneListOrder,
  ],
})
export class DedicatedLanesModule {}
