
<div class="section-table">
  <nz-table #nzTable
    [nzScroll]="{ x: 'scroll' }"
    nzBordered="true"
    [nzData]="orders" nzSize="small"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > orders.length"
    [nzPageSize]="limit"
    [nzLoading]="isLoading"
    [nzPageIndex]="pageIndex"
    [nzNoResult]="tplNoData"
    [nzSize]="'default'"
    (nzPageIndexChange)="onChangePage($event)"
  >
    <ng-template #tplNoData>
      <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-template>
    <tbody>
      <ul class="top15">
        <ng-container *ngFor="let item of nzTable.data">
          <div style="border-bottom: 1px solid #cccc; margin-bottom: 15px;">
            <li>
              <div class="flex-space-between bottom5" style="align-items: center;">
                <div>
                  <span style="margin-right: 5px;">
                    <a [routerLink]="getRouterLink(item)">
                    <code>{{item.code || showOrderWarpId(item.code)}}</code>
                  </a>
                  </span>
                  <span class="txt-gray right5">Created {{ getCreatedDate(item)}} </span>
                  <span *ngIf="getCreatedUserLink(item)" class="txt-gray right5">by <a target="_blank" [href]="getCreatedUserLink(item)" class="txt-gray">{{getCreatedUserName(item)}}</a></span>
                  <span *ngIf="item?.source" class="txt-gray right5">via {{showOrderSource(item.source)}}</span>
                </div>
                <button nzType="primary" nzSize="small" nz-button (click)="onBtnCloneShipment(item)"> Clone</button>
              </div>
              <div *ngIf="item?.displayInfo" class="bottom10" nz-row>
                <div nz-col [nzSpan]="12">
                  <div style="padding-right: 15px;">
                    <div class="bottom10"><span class="pickup-tag">Pickup</span> <span class="medium">{{item.displayInfo.locationNamePick}}</span> {{item.displayInfo.addrPickFull}}</div>
                    <div class="flex right20 bottom10">
                      <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                      <div [innerHTML]="item.displayInfo.timePick ?? 'N/A'"></div>
                    </div>
                    <div *ngIf="item.displayInfo.refNumsPickup?.length > 0">Refs: <nz-tag size="small" *ngFor="let refNum of item.displayInfo.refNumsPickup"
                      style="white-space: normal; display: inline-block; max-width: 100%">{{refNum}}
                    </nz-tag>
                    <i *ngIf="item.displayInfo.refNumsPickup?.length > 0" class="clickable" nz-icon nzType="copy"
                      nzTheme="outline" nz-tooltip nzTooltipTitle="Copy pickup ref" (click)="onBtnCopyPickRef(item)"></i></div>
                  </div>
                </div>
                <div nz-col [nzSpan]="12">
                  <div style="padding-right: 15px;">
                    <div class="bottom10"><span class="dropoff-tag">Dropoff</span> <span class="medium">{{item.displayInfo.locationNameDrop}}</span> {{item.displayInfo.addrDropFull}}</div>
                    <div class="flex right20 bottom10">
                      <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                      <div [innerHTML]="item?.displayInfo?.timeDrop ?? 'N/A'"></div>
                    </div>
                    <div *ngIf="item.displayInfo.refNumsDropoff?.length > 0">Refs: <nz-tag size="small" *ngFor="let refNum of item.displayInfo.refNumsDropoff"
                      style="white-space: normal; display: inline-block; max-width: 100%">{{refNum}}
                    </nz-tag>
                    <i *ngIf="item.displayInfo.refNumsDropoff?.length > 0" class="clickable" nz-icon nzType="copy"
                      nzTheme="outline" nz-tooltip nzTooltipTitle="Copy dropoff ref" (click)="onBtnCopyDropRef(item)"></i></div>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </ng-container>
      </ul>
      
    </tbody>
  </nz-table>
</div>