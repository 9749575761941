import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DedicatedLanesList } from "./list";
import { DedicatedLanesDetail } from "./detail";
import { DedicatedLanesOrderManager } from "./components/manager-order";

const routes: Routes = [
  { path: "", component: DedicatedLanesList, data: { title: "Dedicated Lanes - WARP Admin" } },
  { path: "add", component: DedicatedLanesDetail, data: { title: "Dedicated Lanes - WARP Admin" } },
  // { path: 'create/:jobId', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  // { path: 'edit/:id', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id', component: DedicatedLanesDetail, data: { title: 'Dedicated Lanes - WARP Admin' } },
  { path: ":id/order-manager", component: DedicatedLanesOrderManager, data: { title: "Dedicated Lanes - WARP Admin" } },

  // { path: ':id', redirectTo: '/dashboard/carrier-sales/:id', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DedicatedLanesRoutingModule {}
