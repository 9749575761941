<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="group">
      <div class="tdm-row cell-spacing">
        <ng-container *ngFor="let key of formInputKeys">
          <div class="tdm-col tdm-col-3">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item style="max-width: 800px;">
                <nz-form-control>
                  <ng-container [ngSwitch]="key">
                    <select-by-searching *ngSwitchCase="'clientId'"
                      [version]="2" type="client" [showSubClient]="true"
                      [formControlName]="key"
                      [placeholder]="getPlaceHolder(key)"
                      [(listData)]="listClients">
                    </select-by-searching>
                    <div *ngSwitchCase="'shipmentType'">
                      <nz-select nzBackdrop="true" nzShowSearch
                        [formControlName]="key"
                        [nzPlaceHolder]="getPlaceHolder(key)"
                        (ngModelChange)="onInputChanged($event, key)">
                        <nz-option *ngFor="let item of listShipmentTypes"
                          [nzValue]="item" [nzLabel]="item">
                        </nz-option>
                      </nz-select>
                    </div>

                    <div *ngSwitchCase="'vehicleType'">
                      <div vehicle-selector 
                        (valueChange)="onInputChanged($event, key)" 
                        [(value)]="vehicleType"
                        [isDisabled]="shouldDisableVehicleType"
                        [quoting]="false" [withOptions]="true"></div>
                    </div>

                    <div *ngSwitchCase="'fromZipcode'">
                      <div zipcode-selector 
                        [initZipcode]="getValueInitZipcode(key)" 
                        [isDisabled]="!isCreateNew"
                        placeholder="From Zipcode" 
                        (onNewValue)="onInputChanged($event, key)">
                      </div>    
                    </div>

                    <div *ngSwitchCase="'toZipcode'">
                      <div zipcode-selector 
                      [initZipcode]="getValueInitZipcode(key)" 
                      [isDisabled]="!isCreateNew"
                      placeholder="To Zipcode" 
                      (onNewValue)="onInputChanged($event, key)">
                    </div>    
                    </div>
                    <div *ngSwitchCase="'weeklyOrdersConvert'">
                      <input nz-input
                        ng
                        [formControlName]="key"
                        [type]="getInputType(key)"
                        [placeholder]="getPlaceHolder(key)"
                        (input)="onInputChanged($event, key)"
                        (keypress)="onInputKeyPress($event, key)">
                    </div>
                    <div *ngSwitchCase="'expiredDate'">
                      <nz-date-picker [formControlName]="key" nzFormat="MM/dd/yyyy"style="width: 100%;"></nz-date-picker>
                    </div>

                    <input *ngSwitchDefault nz-input
                      [formControlName]="key"
                      [type]="getInputType(key)"
                      [placeholder]="getPlaceHolder(key)"
                      (input)="onInputChanged($event, key)"
                      (keypress)="onInputKeyPress($event, key)">
                  </ng-container>
                </nz-form-control>
              </nz-form-item>
          </div>
        </ng-container>
        <div class="tdm-col tdm-col-3 top30">
          <div class="flex" style=" align-items: baseline; justify-content: flex-end;">
            <button class="right10" nz-button nzType="default" nzDanger (click)="onBtnDel()"> <span nz-icon nzTheme="outline" nzType="delete"></span>Delete Lane</button>
            <a [routerLink]="[routeAdminDedicatedLanes, id]" nz-button nzType="default"><span nz-icon nzTheme="outline" nzType="edit"></span>Edit</a>
          </div>
        </div>
      </div>
    </div>
    <div class="form-detail">
      <div class="group">
        <div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="6">
              <div class="card">
                <nz-calendar
                  [nzFullscreen]="false"
                  (nzSelectChange)="onCalendarValueChange($event)"
                  (nzPanelChange)="onCalendarPanelChange($event)"
                  [nzDateFullCell]="dateCellTpl"
                ></nz-calendar>

                <ng-template #dateCellTpl let-date>
                  <span *ngIf="!isHighlight(date)">{{ date | date:'d'}}</span>
                  <span *ngIf="isHighlight(date)" 
                    [ngClass]="{highlight: isHighlight(date)}" 
                    style="z-index: 1000;"
                    >{{ date | date:'d'}}
                  </span>
                </ng-template>
              </div>
            </div>
            <div nz-col [nzSpan]="18">
              <div class="flex-space-between">
                <div class="left20 bottom20 medium">{{selectedDate}} - Need to create {{needCreatedOrderCount}} {{needCreatedOrderCount > 1 ? 'orders': 'order'}}</div>
                <button *ngIf="!checkDateExpired" nzSize="small" nz-button nzType="primary" (click)="createOrderManual()">Create</button>
              </div>
              <div dedicated-lane-list-order 
                [orders]="orders"
                [isLoading]="isOrderLoading"
                [totalCount]="totalCount"
                (pageChange)="onChangePage($event)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div>
  
</div>