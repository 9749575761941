import { Component } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { Const } from "@const/Const";
import { ModalHelper } from '@wearewarp/ng-antd';
import { Utils } from '@services/utils';
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { BizUtil } from "@services/biz";
import { ResponseCustomerLaneListItemUI } from "./interface";
import { DedicatedLaneHelper } from "../dedicated-lane.helper";

@Component({
  selector: '[dedicated-lanes-list]',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class DedicatedLanesList extends BaseList {
  get limit() { return 10 }

  constructor(private modalHelper: ModalHelper) {
    super();
  }

  protected getApiUrl(): string {
    return Const.APIV2('dedicated_lane');
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  protected onGetDataSucceeded(resp: any): void {
    for (let item of this.listData) {
      item.displayEquipment = this.getEquipmentName(item);
    }
  }

  getOrigin(item) {
    return `${item.origin.city}, ${item.origin.state}, ${item.origin.zipcode}`;
  }

  getDestination(item) {
    return `${item.destination.city}, ${item.destination.state}, ${item.destination.zipcode}`;
  }

  getEquipmentName(item) {
    if (!item?.vehicleType) return 'N/A';
    const allVehicle = MasterData.getAllVehicleTypes();
    const vehicle = allVehicle.find(v => v.code === item.vehicleType.code);
    vehicle.options = item.vehicleType.options ?? [];
    return BizUtil.getVehicleName(vehicle);
  }

  countTotal: () => Promise< number> = async () => {
    const params = this.prepareParamGetList();
    const apiUrl =  Utils.appendQueryStringIntoUrl(this.getApiUrl(), {...params, countOnly: true});
    const resp = await this.api.GET(apiUrl).toPromise();
    return resp.data;
  }

  getWeeklyOrders(weeklyOrders) {
    return DedicatedLaneHelper.getWeeklyOrders(weeklyOrders);
  }

  public onBtnDelItem(item) {
    this.confirmDeletion({
      message: `Delete Lane <b>${item.code ?? ''}</b>?`,
      fnOk: () => {
        this.deleteItem(item);
      }
    });
  }

  protected deleteItem(item) {
    item.loading = true;
    let url = `${this.getApiUrl()}/${this.getItemId(item)}`;
    this.api.DELETE(this.api.buildUrl(url)).subscribe(
      resp => {
        this.showSuccess(`Lane ${item.code ?? ''} has been deleted successfully.`);
        this.getData();
      }, err => {
        this.showErr(err);
        item.loading = false;
      }
    );
  }

}
