import moment from "moment";
import { Schedule } from "./dedicated-lane.interface";

export class DedicatedLaneHelper {
  static getWeeklyOrders(weeklyOrders) {
    const dayMap = {
      "monday": "Mon",
      "tuesday": "Tue",
      "wednesday": "Wed",
      "thursday": "Thu",
      "friday": "Fri",
      "saturday": "Sat",
      "sunday": "Sun"
    };
    return Object.keys(weeklyOrders)
    .filter(day => weeklyOrders[day].enabled)
    .map(day => `${dayMap[day]} (${weeklyOrders[day].quantity ?? 1})`)
    .join(', ');
  }

  // Hàm lấy số lượng quantity dựa trên ngày đầu vào
  static getQuantityByDate(date: string, schedule: Schedule) {
    const dayOfWeek = moment(date, 'MM/DD/YYYY').format('dddd').toLowerCase(); // lấy tên ngày (ví dụ: "monday")
    const dayInfo = schedule[dayOfWeek];
    if (dayInfo && dayInfo.enabled && dayInfo.quantity !== null) {
      return dayInfo.quantity;
    } else {
      return 0;
    }
  }

  static getDayName(date: Date): string {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return daysOfWeek[date.getDay()];
  }
}