<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
   <div class="group">
      <div class="tdm-row cell-spacing">
        <ng-container *ngFor="let key of ['clientId', 'shipmentType', 'vehicleType', 'fromZipcode', 'toZipcode', 'rate']">
          <div class="tdm-col tdm-col-3">
            <div>
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            </div>
              <nz-form-item style="max-width: 800px;">
                <nz-form-control>
                  <ng-container [ngSwitch]="key">
                    <select-by-searching *ngSwitchCase="'clientId'"
                      [isDisabled]="!isCreateNew"
                      [version]="2" type="client" [showSubClient]="true"
                      [formControlName]="key"
                      [placeholder]="getPlaceHolder(key)"
                      [(listData)]="listClients"
                      (ngModelChange)="onDropdownSelectChange('clientId', $event)"
                    >
                    </select-by-searching>
                    <div *ngSwitchCase="'shipmentType'">
                      <nz-select nzBackdrop="true" nzShowSearch
                        [formControlName]="key"
                        [nzDisabled]="!isCreateNew"
                        [nzPlaceHolder]="getPlaceHolder(key)"
                        (ngModelChange)="onInputChanged($event, key)">
                        <nz-option *ngFor="let item of listShipmentTypes"
                          [nzValue]="item" [nzLabel]="item">
                        </nz-option>
                      </nz-select>
                    </div>

                    <div *ngSwitchCase="'vehicleType'">
                      <div vehicle-selector 
                        (valueChange)="onInputChanged($event, key)" 
                        [(value)]="vehicleType"
                        [isDisabled]="shouldDisableVehicleType"
                        [quoting]="false" [withOptions]="true"></div>
                    </div>

                    <div *ngSwitchCase="'fromZipcode'">
                      <div zipcode-selector 
                        [initZipcode]="getValueInitZipcode(key)" 
                        [isDisabled]="!isCreateNew"
                        placeholder="From Zipcode" 
                        (onNewValue)="onInputChanged($event, key)">
                      </div>    
                    </div>

                    <div *ngSwitchCase="'toZipcode'">
                      <div zipcode-selector 
                        [initZipcode]="getValueInitZipcode(key)" 
                        [isDisabled]="!isCreateNew"
                        placeholder="To Zipcode" 
                        (onNewValue)="onInputChanged($event, key)">
                      </div>    
                    </div>
                    <input *ngSwitchDefault nz-input
                      [formControlName]="key"
                      [type]="getInputType(key)"
                      [placeholder]="getPlaceHolder(key)"
                      (input)="onInputChanged($event, key)"
                      (keypress)="onInputKeyPress($event, key)">
                  </ng-container>
                </nz-form-control>
              </nz-form-item>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="group">
        <ng-container *ngFor="let key of ['weeklyOrders', 'expiredDate']">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item style="max-width: 800px;">
            <nz-form-control>
              <ng-container [ngSwitch]="key">
                <select-by-searching *ngSwitchCase="'clientId'"
                  [isDisabled]="!isCreateNew"
                  [version]="2" type="client" [showSubClient]="true"
                  [formControlName]="key" [apiUrl]="getApiListDataForFilter(key)"
                  [placeholder]="getPlaceHolder(key)"
                  [(listData)]="listClients">
                </select-by-searching>
                <div *ngSwitchCase="'weeklyOrders'">
                  <div [formGroupName]="key">
                    <div class="bottom10" *ngFor="let day of ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']">
                      <div [formGroupName]="day" class="flex">
                        <label style="width: 120px;" class="right20">{{ day | titlecase }}</label>
                        <nz-switch
                          [nzDisabled]="!isCreateNew"
                          style="width: 150px;"
                          class="right20"
                          formControlName="enabled"
                          (ngModelChange)="onChangeWeeklyOrderEnabled(key, day, $event)"
                        ></nz-switch>
                        <ng-container *ngIf="isDayEnable(key, day)">
                          <label class="right20">Order Quantity</label>
                          <nz-input-number [nzDisabled]="!isCreateNew" nzSize="small" formControlName="quantity" [nzMin]="1"></nz-input-number>
                        </ng-container>
                        
                      </div>
                    </div>
                  </div>
                </div>
                  <div *ngSwitchCase="'expiredDate'">
                    <div class="tdm-row cell-spacing">
                      <div class="tdm-col tdm-col-3">
                        <nz-date-picker [formControlName]="key" nzFormat="MM/dd/yyyy"style="width: 100%;"></nz-date-picker>
                      </div>
                    </div>
                  </div>
                
                <input *ngSwitchDefault nz-input
                  [formControlName]="key"
                  [type]="getInputType(key)"
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)"
                  (keypress)="onInputKeyPress($event, key)">
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
    </div>
    <div class = "footer-btn">
      <div detail-footer-buttons labelButtonCreate="Create New"
        [isCreateNew]="isCreateNew" [isEditing]="isEditing" [allowEdit]="true"
        [onProgress]="onProgress" [needUpdate]="needUpdate"
        (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
      </div>
    </div>
  </form>
</div>