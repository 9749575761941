<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div dedicated-lanes-list-filter-component style="flex: 1"></div>
    <button nz-button (click)="onBtnAdd()"><i nz-icon nzType="plus" nzTheme="outline"></i>Create New</button>
  </div>
  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th>ID</th>
          <th>Customer</th>
          <th>Load Details</th>
          <th>Dedicated Lane</th>
          <th>Rate</th>
          <th>Expiration</th>
          <th>Weekly Orders</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a [routerLink]="[routeAdminDedicatedLanes, item.id, 'order-manager']">{{item.code}}</a>
          </td>
          <td>
            <a class="link" target="_blank" [routerLink]="[routeAdminClientList, item.client?.id]">{{item.client?.name}}</a>
          </td>
          <!-- Load Details -->
          <td>
            {{ getShipmentTypeName(item.shipmentType) }} <br/>
            {{ item.displayEquipment }}
          </td>
          <!-- Dedicated Lane -->
          <td>
            {{ getOrigin(item) }} <br/>
            -> {{ getDestination(item) }}
          </td>
          <!-- Rate -->
          <td>
            {{ formatMoney(item.rate) }}
          </td>
          <!-- Expiration -->
          <td>
            {{item.expiredDate ?? 'N/A'}}
          </td>
          <!-- Weekly Orders -->
          <td>
            {{getWeeklyOrders(item?.weeklyOrders)}}
          </td>
          <!-- Actions -->
          <td>
            <a nz-button nzType="text" nzDanger (click)="onBtnDelItem(item)">
              <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip nzTooltipTitle="Delete" nzTooltipPlacement="right"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div w-paginator
      [currentPage]="currentPage"
      [isLastPage]="isLastPage"
      [fnCount]="countTotal"
      (pageChange)="onDataListPageChanged($event)">
    </div>
  </div>
</div>
