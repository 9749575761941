import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: '[dedicated-lanes-list-filter-component]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class DedicatedLanesListFilterComponent extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientIds: {label: 'Customer', notAcceptEmpty: true},
    vehicleType: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
    fromZipcode: {label: 'From zip code', notAcceptEmpty: true},
    toZipcode: {label: 'To zip code', notAcceptEmpty: true},
  }

  public loaded: number = 0;
  public page: number = 1;
  public searchKeyword = '';

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.model = JSON.parse(p.filter);
        }
        catch {
          this.model = {};
        }
      }
      this.searchKeyword = p.search
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'clientIds': return Const.APIURI_CLIENTS_FOR_FILTER;
      default: return
    }
  }

  onInputKeyUp(key: string, event) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    this.onChange(key, this.getItemValue(key));
  }

  private loadData() {
    const condition = this.getFormData(true);
    let q = {page: this.page || 1, loaded: Date.now()}
    if (this.searchKeyword) {
      q['search'] = this.searchKeyword
    }
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q)
  }

  onChange(key: string, value) {
    if (key == 'vehicleType') {
      this.setItemValue(key, value?.code);
    }
    this.page = 1
    this.loadData()
  }

  getVehicleType() {
    const vehicleType = this.getItemValue('vehicleType')
    if (vehicleType) {
      return { code: vehicleType }
    }
    return null;
  }
}
